import { getFeaturesFromData } from "./utils";
import { drawClusterLayer } from "./drawClusterLayer";
import { drawUnclusteredLayer } from "./drawUnclusteredLayer";
/**
 * DrawPoints utility function for adding points to a map based on coordinate data or a FeatureCollection. Will add clustered points and styled markers by default with options for popups and other styles
 * @param {String} sourceName A user defined name used for determining the maplibre data source and the maplibre layers
 * @param {Coordinate[] | Feature[]} data An array of coordinate data or GeoJSON Features used as the data source for maplibre
 * @param {maplibre-gl-js-Map} map A maplibre-gl-js [map](https://maplibre.org/maplibre-gl-js-docs/api/map/) on which the points will be drawn
 * @param {Object} options An object containing options for changing the styles and features of the points rendered to the map, see the options for more details on available settings
 * @param {String} options.showCluster Determines whether or not points close together should be clustered into a single point
 * @param {String} options.clusterOptions Object for determining cluster options, see [ClusterOptions](https://github.com/aws-amplify/maplibre-gl-js-amplify/blob/main/src/types.ts#L43) for more details
 * @param {String} options.unclusteredOptions Object for determining unclustered point options, see [UnclusteredOptions](https://github.com/aws-amplify/maplibre-gl-js-amplify/blob/main/src/types.ts#L8) for more details
 * @param {MAP_STYLE} mapStyle A required parameter that indicates the map style returned from Amazon Location Service. This is used to determine the default fonts to be used with maplibre-gl-js. View existing styles [here](https://github.com/aws-amplify/maplibre-gl-js-amplify/blob/main/src/constants.ts#L8)
 * @returns {DrawPointsOutput} output An object containing the string id's of the sources and layers used to draw the points to the map. This includes the sourceId, clusterLayerId, clusterSymbolLayerId, unclusteredLayerId.
 * @property {String} sourceId The [source](https://maplibre.org/maplibre-gl-js-docs/api/sources/) used to contain all of the coordinate/feature data
 * @property {String} clusterLayerId The [layer](https://maplibre.org/maplibre-gl-js-docs/style-spec/layers/) used for creating and styling the points that are clustered together
 * @property {String} clusterSymbolLayerId The [layer](https://maplibre.org/maplibre-gl-js-docs/style-spec/layers/#symbol) used for creating styling the number that shows the count of points in a cluster
 * @property {String} unclusteredLayerId The [layer](https://maplibre.org/maplibre-gl-js-docs/style-spec/layers) used for creating and styling the individual points on the map and the popup when clicking on a point
 */
export function drawPoints(sourceName, data, map, { showCluster = true, clusterOptions = {}, unclusteredOptions: unclusteredMarkerOptions = {}, autoFit = true, } = {}, mapStyle) {
    var _a, _b;
    if (!map ||
        typeof map.addSource !== "function" ||
        typeof map.addLayer !== "function") {
        throw new Error("Please use a maplibre map");
    }
    /*
     * Convert data passed in as coordinates into features
     */
    const features = getFeaturesFromData(data);
    /*
     * Data source for features
     */
    const sourceId = sourceName;
    map.addSource(sourceId, {
        type: "geojson",
        data: {
            type: "FeatureCollection",
            features,
        },
        cluster: showCluster,
        clusterMaxZoom: (_a = clusterOptions.clusterMaxZoom) !== null && _a !== void 0 ? _a : 14,
        clusterRadius: (_b = clusterOptions.smCircleSize) !== null && _b !== void 0 ? _b : 60,
        generateId: true,
    });
    /*
     * Draw ui layers for source data
     */
    let clusterLayerId, clusterSymbolLayerId;
    if (showCluster) {
        ({ clusterLayerId, clusterSymbolLayerId } = drawClusterLayer(sourceId, map, clusterOptions, mapStyle));
    }
    const { unclusteredLayerId } = drawUnclusteredLayer(sourceId, map, unclusteredMarkerOptions || {});
    if (autoFit) {
        const mapBounds = map.getBounds();
        features.forEach(function (feature) {
            mapBounds.extend(feature.geometry.coordinates);
        });
        map.fitBounds(mapBounds);
    }
    // utility function for setting layer visibility to none
    const hide = () => {
        map.setLayoutProperty(unclusteredLayerId, "visibility", "none");
        if (clusterLayerId)
            map.setLayoutProperty(clusterLayerId, "visibility", "none");
        if (clusterSymbolLayerId)
            map.setLayoutProperty(clusterSymbolLayerId, "visibility", "none");
    };
    // utility function for setting layer visibility to visible
    const show = () => {
        map.setLayoutProperty(unclusteredLayerId, "visibility", "visible");
        if (clusterLayerId)
            map.setLayoutProperty(clusterLayerId, "visibility", "visible");
        if (clusterSymbolLayerId)
            map.setLayoutProperty(clusterSymbolLayerId, "visibility", "visible");
    };
    // utility function updating the data source
    const setData = (data) => {
        const features = getFeaturesFromData(data);
        map.getSource(sourceId).setData({
            type: "FeatureCollection",
            features,
        });
    };
    return {
        sourceId,
        unclusteredLayerId,
        clusterLayerId,
        clusterSymbolLayerId,
        setData,
        show,
        hide,
    };
}
