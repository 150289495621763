var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var _a;
import { Hub, Signer, jitteredExponentialRetry, getAmplifyUserAgent, Credentials, } from "@aws-amplify/core";
import { Geo } from "@aws-amplify/geo";
import { Map as MaplibreMap, } from "maplibre-gl";
import { urlEncodePeriods } from "./utils";
/**
 * An object for encapsulating an Amplify Geo transform request and Amplify credentials
 * @class AmplifyMapLibreRequest
 * @param {ICredentials} currentCredentials Amplify credentials used for signing transformRequests
 * @param {String} region AWS region
 * @return {AmplifyMapLibreRequest} `this`
 *
 */
export default class AmplifyMapLibreRequest {
    constructor(currentCredentials, region) {
        this.refreshCredentials = () => __awaiter(this, void 0, void 0, function* () {
            try {
                this.credentials = yield Credentials.get();
            }
            catch (e) {
                console.error(`Failed to refresh credentials: ${e}`);
                throw e;
            }
        });
        this.refreshCredentialsWithRetry = () => __awaiter(this, void 0, void 0, function* () {
            try {
                const MAX_DELAY_MS = 5 * 60 * 1000; // 5 minutes
                yield jitteredExponentialRetry(this.refreshCredentials, [], MAX_DELAY_MS);
                // Refresh credentials on a timer because HubEvents do not trigger on credential refresh currently
                this.activeTimeout && clearTimeout(this.activeTimeout);
                const expiration = new Date(this.credentials.expiration);
                const timeout = expiration.getTime() - new Date().getTime() - 10000; // Adds a 10 second buffer time before the next refresh
                this.activeTimeout = window.setTimeout(this.refreshCredentialsWithRetry, timeout);
            }
            catch (e) {
                console.error(`Failed to refresh credentials: ${e}`);
            }
        });
        /**
         * A callback function that can be passed to a maplibre map object that is run before the map makes a request for an external URL. This transform request is used to sign the request with AWS Sigv4 Auth. [https://maplibre.org/maplibre-gl-js-docs/api/map/](https://maplibre.org/maplibre-gl-js-docs/api/map/)
         * @param {string} url The function to use as a render function. This function accepts a single [Carmen GeoJSON](https://github.com/mapbox/carmen/blob/master/carmen-geojson.md) object as input and returns a string.
         * @param {string} resourceType The function to use as a render function. This function accepts a single [Carmen GeoJSON](https://github.com/mapbox/carmen/blob/master/carmen-geojson.md) object as input and returns a string.
         * @returns {RequestParameters} [https://maplibre.org/maplibre-gl-js-docs/api/properties/#requestparameters](https://maplibre.org/maplibre-gl-js-docs/api/properties/#requestparameters)
         */
        this.transformRequest = (url, resourceType) => {
            if (resourceType === "Style" && !url.includes("://")) {
                if (this.region == undefined) {
                    throw new Error("AWS region for map is undefined. Please verify that the region is set in aws-exports.js or that you are providing an AWS region parameter to createMap");
                }
                url = `https://maps.geo.${this.region}.amazonaws.com/maps/v0/maps/${url}/style-descriptor`;
            }
            if (url.includes("amazonaws.com")) {
                // only sign AWS requests (with the signature as part of the query string)
                const urlWithUserAgent = url +
                    `?x-amz-user-agent=${encodeURIComponent(urlEncodePeriods(getAmplifyUserAgent()))}`;
                return {
                    url: Signer.signUrl(urlWithUserAgent, {
                        access_key: this.credentials.accessKeyId,
                        secret_key: this.credentials.secretAccessKey,
                        session_token: this.credentials.sessionToken,
                    }),
                };
            }
        };
        this.credentials = currentCredentials;
        this.region = region;
        this.activeTimeout = null;
        this.refreshCredentialsWithRetry();
        Hub.listen("auth", (data) => {
            switch (data.payload.event) {
                case "signIn":
                case "signOut":
                case "tokenRefresh":
                    this.refreshCredentialsWithRetry();
                    break;
            }
        });
    }
}
_a = AmplifyMapLibreRequest;
AmplifyMapLibreRequest.createMapLibreMap = (options) => __awaiter(void 0, void 0, void 0, function* () {
    const { region, mapConstructor = MaplibreMap } = options, maplibreOption = __rest(options, ["region", "mapConstructor"]);
    const defaultMap = Geo.getDefaultMap();
    const amplifyRequest = new AmplifyMapLibreRequest(yield Credentials.get(), region || defaultMap.region);
    const transformRequest = amplifyRequest.transformRequest;
    const map = new mapConstructor(Object.assign(Object.assign({}, maplibreOption), { style: options.style || defaultMap.mapName, // Amplify uses the name of the map in the maplibre style field,
        transformRequest }));
    return map;
});
export const createMap = (options) => __awaiter(void 0, void 0, void 0, function* () {
    return AmplifyMapLibreRequest.createMapLibreMap(options);
});
